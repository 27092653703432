<template>
  <div>
    <div class="box-item">
      <span :style="style_HR_l">0123456789</span>
      <span :style="style_HR_r">0123456789</span>
      <span :style="style_MIN_l">0123456789</span>
      <span :style="style_MIN_r">0123456789</span>
      <span :style="style_SEC_l">0123456789</span>
      <span :style="style_SEC_r">0123456789</span>
      <div class="djs-item" style="margin-left: 6px"></div>
      <div class="djs-item" style="margin-left: 16px"></div>
      <div class="djs-item" style="margin-left: 18px"></div>
    </div>
  </div>
</template>
<script>
export default {
  // props: ["hr", "min", "sec"],
  data() {
    return {
      hr: "00",
      min: "00",
      sec: "00",
      timer: null,
      HR: "00",
      MIN: "00",
      SEC: "00",
      HR_l: "0",
      HR_r: "0",
      MIN_l: "0",
      MIN_r: "0",
      SEC_l: "0",
      SEC_r: "0",
      style_SEC_l: {
        transform: "translate(-50%, -100%)",
        marginLeft: "100px",
      },

      style_SEC_r: {
        transform: "translate(-50%, -100%)",
        marginLeft: "110px",
      },
      style_MIN_l: {
        transform: "translate(-50%, -100%)",
        marginLeft: "55px",
      },

      style_MIN_r: {
        transform: "translate(-50%, -100%)",
        marginLeft: "65px",
      },
      style_HR_l: {
        transform: "translate(-50%, -100%)",
        marginLeft: "15px",
      },

      style_HR_r: {
        transform: "translate(-50%, -100%)",
        marginLeft: "25px",
      },
    };
  },
  mounted() {
    // this.getCode();
  },
  methods: {
    getCode(hr, min, sec) {
      this.HR = parseInt(hr);
      this.MIN = parseInt(min);
      this.SEC = parseInt(sec);

      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.HR || this.MIN || this.SEC) {
            this.SEC_l = parseInt(this.SEC / 10);
            this.SEC_r = this.SEC % 10;
   
            this.style_SEC_l.transform = `translate(-50%, -${
              this.SEC_l * 10
            }%)`;
            this.style_SEC_r.transform = `translate(-50%, -${
              this.SEC_r * 10
            }%)`;

            this.MIN_l = parseInt(this.MIN / 10);
            this.MIN_r = this.MIN % 10;
            this.style_MIN_l.transform = `translate(-50%, -${
              this.MIN_l * 10
            }%)`;
            this.style_MIN_r.transform = `translate(-50%, -${
              this.MIN_r * 10
            }%)`;

            this.HR_l = parseInt(this.HR / 10);
            this.HR_r = this.HR % 10;
            this.style_HR_l.transform = `translate(-50%, -${this.HR_l * 10}%)`;
            this.style_HR_r.transform = `translate(-50%, -${this.HR_r * 10}%)`;

            if (this.SEC > 0) {
              this.SEC_l = parseInt(this.SEC / 10);
              this.SEC_r = this.SEC % 10;
       
              this.style_SEC_l.transform = `translate(-50%, -${
                this.SEC_l * 10
              }%)`;
              this.style_SEC_r.transform = `translate(-50%, -${
                this.SEC_r * 10
              }%)`;
              this.SEC--;
            } else if (this.MIN > 0) {
              this.SEC = 59;
              this.MIN_l = parseInt(this.MIN / 10);
              this.MIN_r = this.MIN % 10;
              this.style_MIN_l.transform = `translate(-50%, -${
                this.MIN_l * 10
              }%)`;
              this.style_MIN_r.transform = `translate(-50%, -${
                this.MIN_r * 10
              }%)`;
              this.MIN--;
            } else if (this.HR > 0) {
              this.MIN = 59;
              this.SEC = 59;
              this.HR_l = parseInt(this.HR / 10);
              this.HR_r = this.HR % 10;
              this.style_HR_l.transform = `translate(-50%, -${
                this.HR_l * 10
              }%)`;
              this.style_HR_r.transform = `translate(-50%, -${
                this.HR_r * 10
              }%)`;
              this.HR--;
            }
          } else {
            this.style_SEC_l.transform = `translate(-50%, 0)`;
            this.style_SEC_r.transform = `translate(-50%,0)`;
            this.style_MIN_l.transform = `translate(-50%, 0)`;
            this.style_MIN_r.transform = `translate(-50%, 0)`;
            this.style_HR_l.transform = `translate(-50%, 0)`;
            this.style_HR_r.transform = `translate(-50%,0)`;
            clearInterval(this.timer);
            this.$parent.getData();
            this.timer = null;
          }
        }, 1000);
      }

      this.SEC_l = this.SEC / 10;
      this.SEC_r = this.SEC % 10;

      this.MIN_l = parseInt(this.MIN / 10);
      this.MIN_r = this.MIN % 10;

      this.HR_l = parseInt(this.HR / 10);
      this.HR_r = this.HR % 10;
    },

    clearCode() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
        this.SEC_l = "0";
        this.SEC_r = "0";
        this.MIN_l = "0";
        this.MIN_r = "0";
        this.HR_l = "0";
        this.HR_r = "0";
        this.style_SEC_l.transform = `translate(-50%, 0)`;
        this.style_SEC_r.transform = `translate(-50%,0)`;
        this.style_MIN_l.transform = `translate(-50%, 0)`;
        this.style_MIN_r.transform = `translate(-50%, 0)`;
        this.style_HR_l.transform = `translate(-50%, 0)`;
        this.style_HR_r.transform = `translate(-50%,0)`;
      }
    },
  },
};
</script>
    <style>
.box-item {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 30px;
  color: #fff;
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  /* background: pink; */

  background-size: 100% 100%;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  /* border: solid 2px #000; */
  position: relative;
  writing-mode: vertical-lr;
  text-orientation: upright;
  overflow: hidden;
  padding-top: 6px;
}

/* 新加部分的代码 */
.box-item span {
  position: absolute;
  top: 10px;
  transform: translate(-50%, -100%);
  transition: transform 0.5s ease-in;
  letter-spacing: 10px;
}
.djs-item {
  width: 26px;
  height: 22px;
  background: #000;
  border-radius: 4px;
}
</style>
