<template>
  <div class="limitGoods">
    <div class="countdown">
      <div class="box">
        <span class="hengx">—</span>
        <img :src="limitImg" alt="" />
        <i
          class="el-icon-time"
          style="margin: 0 4px 0 20px; font-size: 18px"
        ></i>
        <div style="font-size: 14px; display: flex; align-items: center">
          <span style="margin-right: 10px">{{
            timeList[0].status_txt === "进行中" ? "结束于" : "开始于"
          }}</span>
          <TimeCounter ref="djs"> </TimeCounter>
        </div>
        <span class="hengx">—</span>
      </div>
    </div>
    <div class="main-card">
      <div class="time-head">
        <div
          class="item"
          :style="{
            background: item.status_txt == '进行中' ? '#ee4d2d' : '#414142',
            color: item.status_txt == '进行中' ? '#fff' : '#C3C3C3',
          }"
          v-for="(item, i) in timeList"
          :key="i"
        >
          <div>{{ item.ksTime }}</div>
          <div style="font-size: 14px">
            <!-- {{ i == 0 ? "进行中" : "即将来临" }} -->
            {{ item.status_txt }}
          </div>
        </div>
      </div>
      <div class="time-goods_list">
        <LimitGoods
          v-for="item in goodsList.list"
          :key="item.id"
          :data="item"
        ></LimitGoods>
      </div>
    </div>
  </div>
</template>
<script>
import LimitGoods from "@/components/goods/LimitGoods";
import TimeCounter from "@/components/TimeCounter";
import { getLimitPurchase, getLimitPurchaseGoods } from "@/api/buy.js";
export default {
  components: {
    TimeCounter,
    LimitGoods,
  },
  data() {
    return {
      limitImg: require("@/assets/img/home/limit.png"),

      timeList: ["09:00", "12:00", "14:00", "16:00", "20:00"],
      abc: "abc",
      goodsList: "",
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      getLimitPurchase().then((res) => {
        if (res.code == 1) {
  
          this.timeList = res.data;

          let jsTime = res.data[0].end_time * 1000;
          let ksTime = res.data[0].start_time * 1000;
          let timeDiff = "";
          // 时间差的毫秒数
          if (ksTime > Date.now()) {
            timeDiff = ksTime - Date.now();
          } else {
            timeDiff = jsTime - Date.now();
          }
          let days = Math.floor(timeDiff / (24 * 3600 * 1000)); // 计算出天数
          let leavel1 = timeDiff % (24 * 3600 * 1000); // 计算天数后剩余的时间
          let hours = Math.floor(leavel1 / (3600 * 1000)); // 计算天数后剩余的小时数
          let leavel2 = timeDiff % (3600 * 1000); // 计算剩余小时后剩余的毫秒数
          let minutes = Math.floor(leavel2 / (60 * 1000)); // 计算剩余的分钟数
          let leavel3 = timeDiff % (60 * 1000); // 计算剩余小时后剩余的毫秒数
          let sec = Math.floor(leavel3 / 1000); // 计算剩余的分钟数



          this.$nextTick(() => {
            this.$refs.djs.getCode(hours, minutes, sec);
          });
          this.timeList.forEach((item) => {
            let date = new Date(item.start_time * 1000);
            item.ksTime =
              (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
              ":" +
              (date.getMinutes() < 10
                ? "0" + date.getMinutes()
                : date.getMinutes());
          });
          this.getGoods(res.data[0]);
        }
      });
    },
    getGoods(data) {
      getLimitPurchaseGoods({
        start_time: data.start_time,
        end_time: data.end_time,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        if (res.code == 1) {
          this.goodsList = res.data;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.limitGoods {
  background: #f5f5f5;
  padding-bottom: 30px;
  .countdown {
    width: 100%;
    height: 60px;
    background: #fff;
    box-shadow: 0 1px 2px #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    .box {
      display: flex;
      align-items: center;
      .hengx {
        margin: -2px 20px 0;
      }
    }
  }
  .time-head {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .item {
      width: 25%;
      height: 100%;
      font-size: 22px;
      text-align: center;
      padding: 8px 0;
    }
  }
  .time-goods_list {
    display: flex;
    flex-wrap: wrap;
  }
  .time-goods_list .limit-goods:nth-last-child(4n + 1) {
    margin-right: 0%;
  }
}
</style>