<template>
  <div class="limit-goods">
    <div
      style="
        width: 46px;
        height: 42px;
        background-color: rgba(255, 212, 36, 0.9);
        position: absolute;
        right: 0;
        z-index: 1;
        font-size: 14px;
        text-align: center;
        padding: 4px;
      "
    >
      <div style="color: #ee4d2d">
        {{ (parseFloat(data.discount_ratio) / 10).toFixed(1) }}
      </div>
      <div style="color: #fff">折扣</div>
      <div
        style="
          width: 0;
          height: 0;
          left: 0;
          bottom: -4px;
          position: absolute;
          border-color: transparent rgba(255, 212, 36, 0.9);
          border-style: solid;
          border-width: 0 23px 4px;
        "
      ></div>
    </div>
    <div style="padding: 5px">
      <el-image
        style="width: 280px; height: 280px"
        :src="data.goods_image"
        fit="fit"
      ></el-image>
    </div>
    <div class="goods-info">
      <div
        style="
          font-size: 16px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          height: 64px;
        "
      >
        {{ data.goods_name }}
      </div>
      <div
        style="
          text-decoration: line-through;
          font-size: 14px;
          color: #00000021;
          margin: 8px 0 4px;
        "
      >
        RM {{ data.goods_price }}
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div>
          <div style="color: #ee4d2d; font-size: 16px">
            RM
            <span style="font-size: 24px">{{ data.discount_price }}</span>
          </div>
          <div
            style="
              background: #ffbda6;
              width: 150px;
              height: 14px;
              border-radius: 10px;
              position: relative;
            "
          >
            <div
              style="
                height: 14px;
                position: absolute;
                left: 0;
                top: 0;
                width: 20px;
                border-radius: 10px 0 0 10px;
                background: #ee3014;

                z-index: 1;
              "
            ></div>
            <div
              style="
                z-index: 2;
                position: absolute;
                width: 100%;
                text-align: center;
                color: #fff;
                font-size: 12px;
              "
            >
              已售{{ data.sales }}
            </div>
          </div>
        </div>
        <div style="padding-top: 4px">
          <el-button type="primary" @click="toGoodsInfo(data.goods_id)"
            >现在购买</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {};
  },
  methods: {
    toGoodsInfo(id) {
      this.$router.push({ path: "goodsInfo", query: { goodsId: id } });
    },
  },
};
</script>
<style lang="less" scoped>
.limit-goods {
  background: #fff;
  width: 291px;
  height: 452px;
  margin-bottom: 10px;
  margin-right: 1%;
  position: relative;
  .goods-info {
    padding: 6px 14px;
  }
}
</style>